$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	countUpInit();
	loadMoreInit();
	menuSpyInit();

	accordionCollapse();
	toggleNavSticky();
	// serviceHover();
});
// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	toggleBackdrop = $(".toggle-backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .header-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

$(window).on('scroll', function () {
	APP.fixed();
	// header.css('height', heightHeader)
});

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			toggleBackdrop.removeClass('open');
			body.removeClass('overflow-hidden');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-bot",
			desktopMethod: "prependTo",
			breakpoint: 1023.98,
		});
		let footerCertificate = $(".footer-certificate").mapping({
			mobileWrapper: ".footer-policy",
			mobileMethod: "insertAfter",
			desktopWrapper: ".home-banner",
			desktopMethod: "appendTo",
			breakpoint: 1279.98,
		});
		// let headerCaption = $("header .header-caption").mapping({
		// 	mobileWrapper: ".mobile-wrap .navbar-nav-list",
		// 	mobileMethod: "insertBefore",
		// 	desktopWrapper: "header .header-top",
		// 	desktopMethod: "prependTo",
		// 	breakpoint: 1023.98,
		// });
	},
	toggleElm: (button, elmToggle) => {
		button.on("click", function () {
			$(this).toggleClass("open");
			elmToggle.toggleClass('open');
			backdrop.toggleClass('open');
			if (elmToggle == searchWrap)[
				$('.searchbox input').trigger('focus')
			]
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleElm(buttonMenu, mobileWrap);
		APP.toggleElm(buttonSearch, searchWrap);
	}
}

const homeBannerThumb = new Swiper(".home-banner .swiper-thumb .swiper", {
	spaceBetween: 16,
	slidesPerView: 3,
	watchSlidesProgress: true,
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

const homeBannerTop = new Swiper(".home-banner .swiper-top", {
	spaceBetween: 0,
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	thumbs: {
		swiper: homeBannerThumb,
	},
});

const homeResultSwiper = new Swiper(".home-result-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1.25,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-result-swiper .button-next",
		prevEl: ".home-result-swiper .button-prev",
	},
	pagination: {
		el: ".home-result-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

const about2Slider = new Swiper(".about-2-swiper .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 2,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".about-2-swiper .button-next",
		prevEl: ".about-2-swiper .button-prev",
	},
	pagination: {
		el: ".about-2-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 5,
			spaceBetween: 16,
		},
		1280: {
			slidesPerView: 6,
			spaceBetween: 0,
		},
	},
});

const foaNavSlider = new Swiper(".foa-nav-swiper .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 'auto',
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".foa-nav-swiper .button-next",
		prevEl: ".foa-nav-swiper .button-prev",
	},
	pagination: {
		el: ".foa-nav-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		1280: {
			spaceBetween: 32,
		},
		1440: {
			spaceBetween: 40,
		},
	},
});

const foaDetailSlider = new Swiper(".foa-detail-swiper .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".foa-detail-swiper .button-next",
		prevEl: ".foa-detail-swiper .button-prev",
	},
	pagination: {
		el: ".foa-detail-swiper .swiper-pagination",
		clickable: true,
	},
});

const serviceDetailSlider = new Swiper(".service-detail-other-slider .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".service-detail-other-slider .button-next",
		prevEl: ".service-detail-other-slider .button-prev",
	},
	pagination: {
		el: ".service-detail-other-slider .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
	},
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}


function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.count-up').each(function (index) {
		$(this).attr('id', `countUp-${index}`)

		const original_number = $(this).data('count');
		const formatted_number = original_number.replace('.', '').replace(',', '.');
		const endVal = Number(formatted_number);

		const options = {
			decimalPlaces: 2,
			separator: '.',
			decimal: ',',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}

ScrollReveal().reveal(".home-about", {
	beforeReveal: countUpInit,
});

function accordionCollapse() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp()
	});
	$('.accordion-item:nth-child(1)').trigger('click')
}

function loadMoreInit(){
	$('.load-more-table-wrap').each(function () {
		var button = $(this).find('.btn-load-more-table');
		var contentItem = $(this).find('.load-more-table-item');
		var contentItemShow = 8;
		var contentItemLoad = 8;
		var contentItemLength = contentItem.length;
		contentItem.slice(contentItemShow, contentItemLength).addClass('hidden');
		button.on('click', function (e) {
			e.preventDefault();
			console.log('load more table click');
			var $thisLoadMore = $(this).parents('.load-more-table-wrap');
			var visibleItem = $thisLoadMore.find('.load-more-table-item:visible').length;
			var hiddenItem = $thisLoadMore.find('.load-more-table-item:hidden').length;
			contentItem.slice(contentItemLoad, (visibleItem + contentItemLoad)).addClass('show');
			if (hiddenItem <= contentItemLoad) {
				$(this).hide();
			}
		})
	});
}

function menuSpyInit(){
	if ($('.site-nav-sticky').length) {
		$('html, body').addClass('scroll-smooth');
		var elm = document.querySelector('.site-nav-sticky .site-nav-list');
		var ms = new MenuSpy(elm, {
			threshold: widthWindow > 1024 ? 110 : 100,
		});
	}
}

function toggleNavSticky(){
	$('.site-nav-toggle').on('click', function(){
		$('.site-nav-list').toggleClass('open');
	})
	$('.site-nav-list li a').on('click', function(){
		$('.site-nav-list').removeClass('open');
	})
}

// function serviceHover(){
// 	if(widthWindow >= 1280){
// 		$('.service-1-item').on('mouseenter', function(){
// 			$(this).find('.desc').slideDown(200);
// 		}).on('mouseleave', function(){
// 			$(this).find('.desc').slideUp(200);
// 		})
// 	}
// }
